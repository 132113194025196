<template>
    <!--Profile-->
    <div class="profile">
        <div class="row">
            <Sidebar/>
            <div class="col-lg-9">
                <div class="project-list card shadow-sm p-3">
                    <div class="card-header bg-transparent border-0">
                        <div class="row no-gutters">
                            <div class="col-lg-10">
                                <h3>Order History</h3>
                            </div>
                            <div class="col-lg-2 text-right">
                                <div class="selection">
                                  <form action="#">
                                    <select class="form-control shadow-none" v-model="numOfData" @change="pageIndexReset(); showDatas()">
                                        <option value="50" selected="selected">Show 50</option>
                                        <option value="100" selected="selected">Show 100</option>
                                        <option value="200" selected="selected">Show 200</option>
                                        <option value="500" selected="selected">Show 500</option>
                                        <option value="1000" selected="selected">Show 1000</option>
                                    </select> 
                                  </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body table-list">
                        <table class="table table-bordered table-striped table-hover text-center mb-0">
                            <thead>
                                <tr>
                                    <th class="text-center">Date</th>
                                    <th class="text-center">Order ID</th>
                                    <th class="text-center">Price</th>
                                    <th class="text-center">Discount</th>
                                    <th class="text-center">Delivery Charge</th>
                                    <th class="text-center">Total Amount</th>
                                    <th class="text-center">Status</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                           <tbody>
                                    
                          <slot v-if="$store.state.orderHistory!=null">
                            <slot v-if="$store.state.orderHistory.msgFlag==true">
                                <tr v-for="(orderInfo,index) in $store.state.orderHistory.orderHistory.data">
                                  <!--  <pre>
                                    {{orderInfo}}
                                  </pre> --> 
                                  <td>{{orderInfo.created_at | formatDateTime}}</td>
                                  <td>
                                    #MWBD-{{orderInfo.id}}
                                    <slot v-if="orderInfo.isPreOrder==1">
                                      <br><span class="text-light p-1 badge bg-success">Pre Ordered</span>
                                    </slot>
                                  </td>
                                <!--   <td>+8801760 149575</td>
                                  <td>Dhanmondi</td>
                                  <td>Makeup</td> -->
                                 <!--  <td>6</td> -->
                                  <td class="text-right"><strong>৳ {{orderInfo.price | formatNumberWithOutDecimal }}</strong></td>
                                  <td class="text-right"><strong>৳ {{(orderInfo.discount+orderInfo.invoiceDiscount+orderInfo.promoDiscount) |formatNumberWithOutDecimal }}</strong></td>
                                  <td class="text-right"><strong>৳ {{orderInfo.deliveryCharge |formatNumberWithOutDecimal }}</strong></td>
                                  <td class="text-right"><strong>৳ {{getTotalPrice(orderInfo.price,orderInfo.deliveryCharge,orderInfo.discount,orderInfo.invoiceDiscount,orderInfo.promoDiscount)}}</strong></td>
                                  <td class="text-center">
                                      <slot v-if="orderInfo.order_status_info!=null">
                                         <span class="badge"  v-bind:style="{ backgroundColor: orderInfo.order_status_info.bgColor, color:orderInfo.order_status_info.fontColor, padding:5+'px'}" >{{orderInfo.order_status_info.labelForCustomer }}</span> 
                                      </slot>
                                  </td>
                                  <td class="text-center">
                                        <router-link :to="{name:'Invoice', params:{orderId:orderInfo.id}}" class="btn btn-sm btn-success">View</router-link>
                                        <slot v-if="orderInfo.isBkashPaid!=1 && orderInfo.isOnlinePaid!=1 && orderInfo.isCancelled!=1  && orderInfo.isDelivered!=1 && orderInfo.isShipping!=1 ">
                                          <a @click="payNow(orderInfo.id)" class="btn btn-sm btn-info ml-1">Pay Now</a>
                                        </slot>
                                  </td>
                                </tr>
                            </slot>
                          </slot>
                          <slot v-else>
                              <tr>
                                <td colspan="8">
                                  <slot v-if="$store.state.orderHistory!=null">
                                     {{$store.state.orderHistory.errMsg}}
                                   </slot>
                                </td>
                              </tr>
                          </slot>
                         
                           </tbody>

                           <tfoot>
                                <tr>
                            <th colspan="8" class="text-right">
                              <slot v-if="$store.state.orderHistory!=null">
                                <paginate
                                    :page-count="$store.state.orderHistory.orderHistory.last_page"
                                    :click-handler="dataByPaginate"
                                    :prev-text="'<'"
                                    :next-text="'>'"
                                    :container-class="'pagination justify-content-end'"
                                    :page-class="'page-item'"
                                    :page-link-class="'page-link'"
                                    :prev-class="'page-item'"
                                    :prev-link-class="'page-link'"
                                    :next-class="'page-item'"
                                    :next-link-class="'page-link'"
                                    :first-last-button="true"
                                    :first-button-text="'<<'"
                                    :last-button-text="'>>'"                                    
                                    >
                                   <span slot="prevContent">Changed previous button</span>
                                    <span slot="nextContent">Changed next button</span>
                                    <span slot="breakViewContent">
                                      <svg width="16" height="4" viewBox="0 0 16 4">
                                        <circle fill="#999999" cx="2" cy="2" r="2" />
                                        <circle fill="#999999" cx="8" cy="2" r="2" />
                                        <circle fill="#999999" cx="14" cy="2" r="2" />
                                      </svg>
                                    </span>
                              </paginate>
                            </slot>
                            </th>
                          </tr>
                           </tfoot>
                        </table>
                    </div>
                    <!-- <div class="card-footer bg-transparent border-0">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <p class="mb-0">Showing 1 to 10 of 100 Products</p>  
                            </div>
                            <div class="col-lg-6">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                        <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                        </li>
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                        </li>
                                    </ul>
                                    </nav>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!--/Profile-->
</template>

<script>
import Sidebar from './elements/Sidebar';

export default {
    components: {
        Sidebar,
    },
    data() {
        return {
            customerPhone:this.$store.state.customerPhone,
            token:this.$store.state.apiToken,
            remember:this.$store.state.rememberToken,
            customerPhone:this.$store.state.customerPhone,
            orderHistory:[],
            msgFlag:false,
            errMsgFlag:false,
            msg:null,
            errMsg:null,
            numOfData:50,
            urlParam:{
              numOfData:50,
            },
            dataUrl:'order/history',
            numOfDataPage:1,
            lastPage:1,
      }
    },
    mounted(){
      this.getCustomerInfo();
       // this.getDataList(this.dataUrl,this.urlParam);
       this.showDatas();
    },
    watch:{
        $route (to, from){ 
            this.getCustomerInfo();
            // this.getDataList(this.dataUrl,this.urlParam);
            this.showDatas();
         }
    },
    methods:{
      getTotalPrice(price,deliveryCharge,productDiscount,invoiceDiscount,promoDiscount){
        let totalPrice=0;
        let totalDiscount=0;
        price=parseInt(price);
        deliveryCharge=parseInt(deliveryCharge);
        productDiscount=parseInt(productDiscount);
        invoiceDiscount=parseInt(invoiceDiscount);
        promoDiscount=parseInt(promoDiscount);
        totalPrice=price+deliveryCharge;
        totalDiscount=productDiscount+invoiceDiscount+promoDiscount;
        return totalPrice-totalDiscount;
      },
       getDataList(url,formData) {
          
          this.$http.post(url,formData)
            .then(response => {
                if(response.data.msgFlag){
                    this.lastPage=response.data.orderHistory.lastPage;
                    this.$store.commit("storeOrderHistory",response.data);
                }
            })
            .catch(error => {
              console.dir(error);
            });
      
            
     },
    pageIndexReset(){
      this.numOfDataPage=1;
    },
    dataByPaginate(pageIndex){
      this.numOfDataPage=pageIndex;
      this.showDatas();
    },
    showDatas(){
      if(this.$store.state.customerInfo!=null){
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);
            formData.append("numOfData",this.numOfData);
            formData.append("searchKey",this.searchKey);
            formData.append("page",this.numOfDataPage);

            this.getDataList(this.dataUrl,formData);
          }
    },
    getOrderHistory(){
        let customerInfo=this.$store.state.customerInfo;
        let formData=new FormData();
        formData.append("phone",customerInfo.phone);
        formData.append("remember",customerInfo.remember);
        formData.append("token",customerInfo.token);

        this.$http.post("order/history",formData)
         .then(response=>{
            if(response.data.msgFlag==true){
              this.$store.commit("storeOrderHistory",response.data);
            }
            else{
            }
         })
      },
      getCustomerInfo(){
        if(this.$store.state.customerInfo!=null){
            let customerInfo=this.$store.state.customerInfo;
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);

            this.$http.post("auth/check",formData)
            .then(response=>{
              if(response.data.msgFlag==true){
                   this.$store.commit("storeCustomerInfo",response.data.customerInfo);
              }
              else{
                // this.$store.commit("storeCustomerInfo",null);
                this.$router.push({name:'Home'});
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
        }
        else{
           this.$router.push({name:'Home'});
        }
      },
     payNow(orderId){ window.open(this.$store.state.onlinePaymentUrl+orderId+"/online/payment","_blank");}
    }
}
</script>

<style>

</style>