<template>
	<div class="col-lg-3">
	    <div class="profile-sidebar card shadow-sm">
	        <div class="card-body">
	        <slot v-if="$store.state.customerInfo!=null">
	            <div class="user-profile">
	                <img :src="$store.state.customerInfo.avatar" :alt="$store.state.customerInfo.name">
	                <!-- <span></span> -->
	            </div>
	            <div class="user-details">
	                <h3>{{$store.state.customerInfo.name}}</h3>
	            </div>
	            <div class="user-address">
	                <!-- <p><i class="mdi mdi-map-marker-outline pr-1"></i> 43 Sukrabad, Dhanmondi, Dhaka</p> -->
	                <p><strong>Phone:</strong>{{$store.state.customerInfo.phone}}</p>
	                <p><strong>Email:</strong>{{$store.state.customerInfo.email}}</p>
	            </div>
	        </slot>
	            <!-- <div class="user-social">
	                <ul class="nav flex-row justify-content-center">
	                    <li class="nav-item">
	                        <a href="#" class="nav-link"><i class="mdi mdi-facebook"></i></a>
	                    </li>
	                    <li class="nav-item">
	                        <a href="#" class="nav-link"><i class="mdi mdi-instagram"></i></a>
	                    </li>
	                    <li class="nav-item">
	                        <a href="#" class="nav-link"><i class="mdi mdi-twitter"></i></a>
	                    </li>
	                    <li class="nav-item">
	                        <a href="#" class="nav-link"><i class="mdi mdi-youtube"></i></a>
	                    </li>
	                </ul>
	            </div> -->
	            <div class="profile-menu">
	                <ul class="nav flex-column">
	                    <li class="nav-item">
	                        <router-link :to="{name:'Dashboard'}" class="nav-link active"><i class="mdi mdi-view-dashboard-outline pr-1"></i>Dashboard</router-link>
	                    </li>
	                    <li class="nav-item">
	                        <router-link :to="{name:'Profile'}" class="nav-link"><i class="mdi mdi-cog-outline pr-1"></i>Profile</router-link>
	                    </li>
	                    <li class="nav-item">
	                        <a href="#" class="nav-link" v-on:click="logoutFunction()"><i class="mdi mdi-logout pr-1"></i>Logout</a>
	                    </li>
	                </ul>
	            </div>
	        </div>
	    </div>
	</div>
</template>
<script>
export default {

    data() {
        return {
        }
    },
    methods:{
        logoutFunction(){
           window.localStorage.removeItem("customerInfo");
           this.$store.commit("storeCustomerInfo",null);
           this.$router.push({name:'Home'});
           this.$toaster.success("Successfully Logged Out.");
        },
    }
}
</script>